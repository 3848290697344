export default {
  files: [],
  property_type_id: 2,
  property_type_name: 'Частная земля',
  rn: 1,
  beautification_rules_old: 84,
  source_reference: null,
  status_change_date: null,
  violation_type_id: 1,
  violation_type_name: null,
  address_district_id: null,
  address_district_name: null,
  address_house: null,
  address_info: null,
  address_street: null,
  appeal_deadline_date: null,
  area_id: null,
  area_report_name: null,
  audit_doc_date: null,
  audit_doc_number: null,
  beautification_rules: null,
  beautification_rules_name: null,
  beautification_rules_name_old: null,
  coes_notification_decision_dismatle_date: null,
  compliance_crosswalk_requirements: true,
  compliance_cultural_heritage_requirements: true,
  compliance_facade_requirements: true,
  compliance_fields_requirements: true,
  compliance_house_territory_requirements: true,
  compliance_kind_requirements: false,
  creation_date: null,
  d_object_status: null,
  d_object_status_desc: null,
  d_object_status_name: null,
  date_claim_object_owner: null,
  date_of_caution: null,
  delete_comment: null,
  delete_date: null,
  description: null,
  detection_date: null,
  dismantling_date: null,
  dismantling_decision_date: null,
  dismantling_decision_number: null,
  expiration_date: null,
  file_audit_doc_files: null,
  file_audit_doc_photos: null,
  file_delete_reason: null,
  file_nto_private_place_claim_object_owner: null,
  file_nto_private_place_coes_decisions: null,
  file_nto_private_place_confirming_ownership: null,
  file_nto_private_place_decision_dismantle_objects: null,
  file_nto_private_place_forced_dismantling_photos: null,
  file_nto_private_place_judicial_decision: null,
  file_nto_private_place_materials_emergency_meeting: null,
  file_nto_private_place_nto_notification: null,
  file_nto_private_place_permitted_use: null,
  file_nto_private_place_voluntary_dismantling_docs: null,
  file_nto_private_place_voluntary_dismantling_photos: null,
  file_nto_private_place_warning: null,
  file_object_move_docs: null,
  forced_dismantling_date: null,
  forced_dismantling_mark: true,
  full_status_name: null,
  geo_lat: null,
  geo_lon: null,
  geo_polygon: null,
  geo_type: 'point',
  guid: null,
  is_deleted: false,
  is_moved: false,
  judicial_decision_date: null,
  last_change_status_date: null,
  last_update_date: null,
  log: [],
  mark_dismantling: true,
  mark_on_functioning: null,
  mcs_actions: null,
  mcs_actions_name: null,
  mcs_mail_status: null,
  mcs_mail_status_name: null,
  meeting_submission_date: null,
  move_data: null,
  move_obj_registration_date: null,
  move_obj_registration_number: null,
  nto_notification_date: null,
  obj_registration_date: null,
  obj_registration_number: null,
  object_functioning_mark: null,
  object_functioning_mark_name: null,
  object_specialization: null,
  object_specialization_name: null,
  object_type: null,
  object_type_description: null,
  object_type_name: null,
  obstructs_passage: null,
  offence: [],
  offences_count: 0,
  owner_email: null,
  owner_fullname: null,
  owner_id: null,
  owner_name: null,
  owner_object_inn: null,
  owner_object_name: null,
  owner_object_official: null,
  owner_object_opf: null,
  owner_object_opf_name: null,
  owner_object_status: null,
  owner_object_status_name: null,
  owner_organization: null,
  owner_phone_add: null,
  owner_phone_work: null,
  owner_place_inn: null,
  owner_place_name: null,
  owner_place_official: null,
  owner_place_opf: null,
  owner_place_opf_name: null,
  owner_place_status: null,
  owner_place_status_name: null,
  owner_position: null,
  permitted_use_kind: null,
  permitted_use_kind_description: null,
  permitted_use_kind_name: null,
  place_15m_to_facade: null,
  place_cadastral_number: null,
  place_electric_networks: null,
  place_electric_networks_address: null,
  place_electric_networks_description: null,
  place_electric_networks_mail_status: null,
  place_electric_networks_mail_status_name: null,
  place_electric_networks_name: null,
  place_electric_networks_status: null,
  place_electric_networks_status_name: null,
  place_first_line_street: null,
  place_first_line_street_name: null,
  place_in_arches_of_buildings: null,
  place_in_area_of_engineering_networks: null,
  place_in_the_houses_area: null,
  place_with_violation_of_requirements: null,
  place_within_lane_of_road: null,
  place_without_type_permitted_use: null,
  voluntary_dismantling_expiration_date: null
};
